import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampAction";
import * as userActions from "../../actions/userActions"
import { updateTimestamp } from "../../actions/timestampAction";
import Spinner from "../../components/customComponents/Spinner";
const AddContact = () => {
    const username = React.useRef();
    const email = React.useRef();
    const telegram = React.useRef();
    const description = React.useRef();
    const navigate = useNavigate();
    const imageInputRef = React.useRef();
    const [image, setImage] = React.useState()
    const [spinner, setSpinner] = React.useState(false);
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    let userDataDispatch = useDispatch();
    let userDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);
    React.useEffect(() => {
        if (userData.isLoggedIn === false)
            animateNavigate('/menu')
    }, [userData])

    const addContact = () => {
        if(spinner) return;

        if(!username.current.value) {
            document.querySelector('.errorMsg').textContent = "Firt and Last name field can't be empty";
            return;
        }
        if(!telegram.current.value){
            document.querySelector('.errorMsg').textContent = "Telegram / Skype field can't be empty";
            return 
        } 

        let fd = new FormData()
        fd.append("UserImage", image);
        fd.append("Username", username.current.value)
        fd.append("Email", email.current.value)
        fd.append("Telegram", telegram.current.value)
        fd.append("Description", description.current.value)
        fd.append("Converted", false)
        fd.append("Contacted", false)

        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/contacts/createNewContact`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: { 'content-type': 'multipart/form-data' },
        }).then((e) => {
            if (e.data.status === 'ok') {

                animateNavigate('/menu')

            } else {
                document.querySelector('.errorMsg').innerHTML = 'Please check data '
            }

        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            setSpinner(false)
        })
    }
    return <div className="component__menu">
        <div className="component__login__wraper">
            <p className="component__login__wraper__goback" onClick={() => { animateNavigate('/menu') }} >Go back</p>
            <div className="component__menu__container">
                <div>
                    <label>First and Last name</label>
                    <input placeholder="" type='text' required ref={username} />
                </div>
                <div>
                    <label>E-mail</label>
                    <input placeholder="" type='text' required ref={email} />
                </div>
                <div>
                    <label>Telegram / Skype</label>
                    <input placeholder="" type='text' required ref={telegram} />
                </div>
                <div>
                    <label>Description</label>
                    <textarea placeholder="" required ref={description} ></textarea>
                </div>
                <p title="Pošalji sliku" src="/images/chat_imageUpload.svg" onClick={() => imageInputRef?.current?.click()} > Add photo  </p>
                <div id='preview' src={image} ></div>
                <input ref={imageInputRef} onChange={(e) => {
                    let preview=document.getElementById('preview')
                     let newImg = new Image(100, 100);
                   
                     newImg.src = e.target.files[0];
                     newImg.src = URL.createObjectURL(e.target.files[0]);
                   preview.innerHTML=''
                     preview.appendChild(newImg);
                    setImage(e.target.files[0])
                }} type="file" style={{ 'display': 'none' }} accept="image/*;capture=user" />
                <p className="errorMsg"></p>
                <button onClick={() => { addContact() }}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Save"}
                </button>

            </div>

        </div>
    </div >
};

export default AddContact;