import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const CustomInput = React.forwardRef((props, ref) => {
    const themeData = (props.theme === "light" || props.theme === "dark") ? props.theme : "light"
    
    return <div className={`customComponents__input customComponents__input--${themeData}`}>
        <input ref={ref} placeholder="" className="customComponents__input__textbox" type={props.type ?? "text"} />
        <span className="customComponents__input__placeholder">{props.placeholder ?? ""}</span>
    </div>
});

CustomInput.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    type: PropTypes.oneOf(["text", "number", "password", "date", "time", "color"]),
    placeholder: PropTypes.string
};

CustomInput.displayName = "CustomInput";

export default CustomInput