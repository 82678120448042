import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampAction";
import * as userActions from "../../actions/userActions"
import { updateTimestamp } from "../../actions/timestampAction";
const Menu = () => {
    const username = React.useRef();
    const password = React.useRef();
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const [contacts, setContacts] = React.useState();
    const [contacted, setContacted] = React.useState();
    const [converted, setConverted] = React.useState();
    const [undecisiv, setUndecisiv]=React.useState();
    let userDataDispatch = useDispatch();
    let userDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);
    React.useEffect(() => {
        if (userData.isLoggedIn === false)
            animateNavigate('/')
    }, [userData])

    const logout = () => {
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            animateNavigate('/')

        }).catch((e) => {
        }).finally(() => {
            userDispatch(updateTimestamp());
        });
    }

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/contacts/getAllContacts`,
            ...backendModule.axiosConfig
        }).then((e) => {
            let contacted = 0;
            let converted = 0;
            let third=0;
            if (e.data.status === 'ok') {
                setContacts(e.data.data)
                e.data.data.forEach((item) => {
                    if (item.Contacted === true) {
                        contacted++
                    }
                    if (item.Converted === true) {
                        converted++
                    }
                    if (item.Converted === null) {
                        third++
                    }
                })
                setContacted(contacted);
                setConverted(converted);
                setUndecisiv(third);
            }


        }).catch((e) => {
        })
    }

    React.useEffect(() => {
        getData()
    }, [])

    return <div className="component__menu">
        <div className="component__login__wraper component__main">
            {/* <p >Go back</p> */}
            <div className="component__main__head">
                <button onClick={() => { animateNavigate('/addcontact') }}>Add contact</button>
                <button onClick={() => { animateNavigate('/contacts') }}>Preview all contacts</button>
                <button onClick={() => { animateNavigate('/adduser') }}>Add user</button>
                <button onClick={() => { animateNavigate('/transfer') }}>Transfer contacts</button>
            </div>
            <div className="component__main__dashboard">
                <div className="component__main__dashboard__kpi">
                    <p>Number of contacts: </p>
                    <p>{contacts?.length}</p>
                </div>
                <div className="component__main__dashboard__kpi">
                    <p>Number of contacted contacts: </p>
                    <p>{contacted}</p>
                </div>
                <div className="component__main__dashboard__kpi">
                    <p>Number of undecisiv contacts: </p>
                    <p>{undecisiv}</p>
                </div>
                <div className="component__main__dashboard__kpi">
                    <p>Number of converted contacts: </p>
                    <p>{converted}</p>
                </div>
            </div>
            <button onClick={() => { logout() }}>Logout</button>
        </div>

    </div>
};

export default Menu;