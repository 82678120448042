import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampAction";
import * as userActions from "../../actions/userActions"

const Login = () => {
    const username = React.useRef();
    const password = React.useRef();
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    let userDataDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);

    const checklogin = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === "ok") {
                if (!userData?.isLoggedIn) {
                    userDataDispatch(userActions.setUserData(e.data.data));
                    userDataDispatch(userActions.setLoginStatus(true));
                    animateNavigate('/menu')
                } else {
                    userDataDispatch(userActions.setUserData(e.data.data));
                };
            }
        }).catch((e) => {

        });

    }
    const userLogin = (e) => {
        e.preventDefault()

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data: {
                username: username.current.value,
                password: password.current.value,
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                userDataDispatch(timestampActions.updateTimestamp());
                animateNavigate('/menu')
            } else {
                document.querySelector('.component__login__wraper__form__errorMsg').style.opacity = '1'

            }
        }).catch((e) => {
            document.querySelector('.component__login__wraper__form__errorMsg').style.opacity = '0'

        });
    }
    React.useEffect(() => {
        checklogin()
    }, [])
    return <div className="component__login">
        <div className="component__login__wraper">
            <img alt="logo" src="/images/logo.svg" />
            <form className="component__login__wraper__form" onSubmit={(e) => {
                userLogin(e)
            }}>
                <div>
                    <p>Welcome back</p>
                    <h1>Login to your account</h1>
                </div>
                <div><label>Username</label>
                    <input placeholder="Enter your username" type='text' required ref={username} /></div>
                <div>
                    <label>Password</label>
                    <input placeholder="Enter your password" type='password' required ref={password} />
                </div>


                <p className="component__login__wraper__form__errorMsg">Username or password is incorrect</p>
                <button type="submit" >Login now</button>

            </form>
        </div>

    </div>
};

export default Login;