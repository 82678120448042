import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampAction";
import * as userActions from "../../actions/userActions"
import { updateTimestamp } from "../../actions/timestampAction";
import Spinner from "../../components/customComponents/Spinner";
const AddUser = () => {
    const [spinner, setSpinner] = React.useState(false);
    const username = React.useRef();
    const email = React.useRef();
    const password = React.useRef();
    const description = React.useRef();
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    let userDataDispatch = useDispatch();
    let userDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);
    React.useEffect(() => {
        if (userData.isLoggedIn === false)
            animateNavigate('/menu')
    }, [userData])

    const addContact = () => {
        if(spinner) return;

        if(!username.current.value){
            document.querySelector('.errorMsg').textContent = "Username can't be empty";
            return;
        }
        if(!password.current.value){
            document.querySelector('.errorMsg').textContent = "Password can't be empty";
            return;
        }

        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createNewUser`,
            data: {
                username: username.current.value,
                email: email.current.value,
                password: password.current.value
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                animateNavigate('/menu')
            } else {
                document.querySelector('.errorMsg').innerHTML = 'Please check data '
            }

        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            setSpinner(false)
        })
    }
    return <div className="component__menu">
        <div className="component__login__wraper">
            <p className="component__login__wraper__goback" onClick={() => { animateNavigate('/menu') }} >Go back</p>
            <div className="component__menu__container">
                <div>
                    <label>Username</label>
                    <input placeholder="" type='text' required ref={username} />
                </div>
                <div>
                    <label>Password</label>
                    <input placeholder="" type='text' required ref={password} />
                </div>
                <div>
                    <label>E-mail</label>
                    <input placeholder="" type='text' required ref={email} />
                </div>
                <p className="errorMsg"></p>
                <button onClick={() => { addContact() }}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Save"}
                </button>

            </div>

        </div>

    </div >
};

export default AddUser;