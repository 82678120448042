import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampAction";
import * as userActions from "../../actions/userActions"
import { updateTimestamp } from "../../actions/timestampAction";
import {FilteredCustomTable} from "../../components/customComponents/Table";

const Contacts = () => {
    const [contacts, setContacts] = React.useState();
    const [edit, setEdit] = React.useState([false, null])
    const [filters, setFilters] = React.useState([]);
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    let userDataDispatch = useDispatch();
    let userDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);
    React.useEffect(() => {
        if (userData.isLoggedIn === false)
            animateNavigate('/menu')
    }, [userData])

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/contacts/getAllContacts`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                setContacts(e.data.data)
            }


        }).catch((e) => {
        })
    }

    React.useEffect(() => {
        if (edit[0] === false) getData()
    }, [edit, filters])

    return <div className="component__menu">
        <div className="component__login__wraper">
            <p className="component__login__wraper__goback" onClick={() => { animateNavigate('/menu') }} >Go back</p>
            <div className="tableContainer">
                <FilteredCustomTable
                    theme="dark"
                    headers={["Name", "E-mail", "Telegram", "Contacted", "Converted"]}
                    filters={[
                        {name: "Username", friendlyName: "Name", type: "string"},
                        {name: "Email", friendlyName: "E-mail", type: "string"},
                        {name: "Telegram", friendlyName: "Telegram", type: "string"},
                        {name: "Description", friendlyName: "Description", type: "string"},
                        {name: "Contacted", friendlyName: "Contacted", type: "boolean"},
                        {name: "Converted", friendlyName: "Converted", type: "boolean"}
                    ]}
                    filterCB={fi => setFilters(fi)}
                    data={(()=>{
                        if (!contacts) return [[{keyID: "noData-text", type: "text", text: "Loading..."}]]
                        if (contacts.length === 0) return [[{keyID: "noData-empty", type: "custom", data: <p style={{textAlign: "left"}}>Nothing to show...</p>}]]
                        return contacts.map(contact => {
                            return [
                                {keyID: String(contact.ID), type: "text", text: contact.Username},
                                {keyID: String(contact.ID), type: "text", text: contact.Email},
                                {keyID: String(contact.ID), type: "text", text: contact.Telegram},
                                {keyID: String(contact.ID), type: "text", text: <p className="tableContainer__dot" style={{
                                    backgroundColor: contact.Contacted ? "green" : "red"
                                }}>{contact.Contacted}</p>},
                                {keyID: String(contact.ID), type: "text", text: <p className="tableContainer__dot" style={{
                                    backgroundColor: contact.Converted ? "green" : "red"
                                }}>{contact.Converted}</p>},
                                {keyID: String(contact.ID), type: "groupNewline", group: [
                                    {keyID: String(contact.ID), type: "text", text: <p style={{maxWidth: "100%", wordBreak: "break-word", fontFamily: "Roboto", color: "#a6a4a4"}}>{contact.Description}</p>}
                                ]},
                                {keyID: String(contact.ID), type: "groupNewline", group: [
                                    {keyID: String(contact.ID), type: "button", text: "Edit", onClick: () => { setEdit([true, contact]) }},
                                    {keyID: String(contact.ID), type: "button", text: "View image", onClick: () => {
                                        window.open(contact.ArticleImage, "_blank");
                                    }}
                                ]}
                            ];
                        });
                    })()}
                />
            </div>
            {edit[0] === true && <EditModal data={edit[1]} onClose={() => { setEdit([false, null]) }} />}


        </div>

    </div >
};

const EditModal = (props) => {
    const [contacted, setContacted] = React.useState(props.data.Contacted);
    const [converted, setConverted] = React.useState(props.data.Converted);

    const editContact = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/contacts/editContact`,
            data: {
                ID: props.data.ID,
                Contacted: contacted,
                Converted: converted
            },

            ...backendModule.axiosConfig
        }).then(res => {
            props.onClose()
        })
    }

    return <div className="component__edit">
        <div className="component__edit__container">
            <p>ID: {props.data.ID}</p>
            <p>Name: {props.data.Username}</p>
            <p>Email: {props.data.Email}</p>
            <p>Telegram: {props.data.Telegram}</p>
            <p>Contacted: {contacted ? 'Yes' : "No"} <button onClick={() => { setContacted(!contacted) }} >Change</button></p>
            <p>Converted: {converted ? 'Yes' : "No"} <button onClick={() => { setConverted(!converted) }}>Change</button></p>
            <div>
                <button onClick={() => { editContact() }}>Save</button>
                <button onClick={() => { props.onClose() }}>Cancel</button>
            </div>
        </div>


    </div>

}

export default Contacts;