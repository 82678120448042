import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampAction";
import * as userActions from "../../actions/userActions"
import { updateTimestamp } from "../../actions/timestampAction";
import Spinner from "../../components/customComponents/Spinner";
const TransferContacts = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [users, setUsers] = React.useState();
    const [selectedUsers, setSelectedUsers] = React.useState([]);

    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    React.useEffect(() => {
        getAllUsers();
    }, [])

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersExceptLogged`,
            data: {
                
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                setUsers(e.data.data)
            }
        }).catch((e) => {
        })
    }

    const transferContacts = () => {
        if(spinner) return;
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/contacts/changeCreator`,
            data: {
                users : selectedUsers
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                animateNavigate('/menu')
            } else {
                document.querySelector('.errorMsg').innerHTML = 'Please check data '
            }

        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            setSpinner(false)
        })
    }

    const addSelectedUser = (id) => {
        if(selectedUsers.includes(id)){
            selectedUsers.forEach((elem, index) => {
                if(elem === id){
                    selectedUsers.splice(index, 1)
                }
            })
            return
        }else{
            setSelectedUsers([...selectedUsers, id])
        }
    }

    const addActiveItemClass = (item) => {
        if(item.currentTarget.classList.contains('component__transfer__container__users__item--active')){
            item.currentTarget.classList.remove('component__transfer__container__users__item--active')
        }else{
            item.currentTarget.classList.add('component__transfer__container__users__item--active')
        }
    }

    return <div className="component__menu">
        <div className="component__login__wraper">
            <p className="component__login__wraper__goback" onClick={() => { animateNavigate('/menu') }} >Go back</p>
            <div className="component__transfer__container">
                <p className="component__transfer__container__transfer-text">Transfer all contacts to selected accounts</p>
                <div className="component__transfer__container__users">
                    {users ? users.map(elem => <p className="component__transfer__container__users__item" onClick={ (e) => {addSelectedUser(elem.ID); addActiveItemClass(e);}}>{elem.Username}</p> ):''}
                </div>
                <p className="errorMsg"></p>
                <button onClick={() => { transferContacts(); }}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Transfer"}
                </button>
            </div>
        </div>
    </div >
};

export default TransferContacts;