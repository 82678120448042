import {HashRouter as Router, Routes, Route} from "react-router-dom";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";

import Login from "./routes/Login";
import Menu from "./routes/Menu";
import AddContact from "./routes/AddContact";
import Contacts from "./routes/Contacts";
import AddUser from "./routes/AddUser";
import TransferContacts from "./routes/TransferContacts";

const App = () => {
  return <>
    <ContentWrapper>
      <Routes>
      <Route path="/menu" element={<Menu/>} />
        <Route path="/" element={<Login />} />
        <Route  path="/addcontact"  element={<AddContact/>}/>
        <Route  path="/contacts"  element={<Contacts/>}/>
        <Route  path="/addUser"  element={<AddUser/>}/>
        <Route  path="/transfer"  element={<TransferContacts/>}/>
        
      </Routes>
    </ContentWrapper>
  </>
};

export default App;
